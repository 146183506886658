import { createRouter, createWebHistory } from 'vue-router';

const Login = () => import('../views/Login.vue');
const Carregamento = () => import('../views/Carregamento.vue');
const Resumo = () => import('../views/Resumo.vue');
const TelaTarefas = () => import('../views/TelaTarefas.vue');
const TarefasValidade = () => import('../views/TarefasValidade.vue');
const TarefasVerificacao = () => import('../views/TarefasVerificacao.vue');
const TelaProdutos = () => import('../views/TelaProdutos.vue');
const ProdutosDescricao = () => import('../views/ProdutosDescricao.vue');
const IncluirProdutos = () => import('../views/IncluirProdutos.vue');
const ProdutosEdit = () => import('../views/ProdutosEdit.vue');

const routes = [
    {
        path: '/',
        name: 'login',
        component: Login,
        meta: { hasNoRefresh: true, title: 'Login', isLogin: true },
    },
    {
        path: '/carregamento',
        name: 'carregamento',
        component: Carregamento,
        meta: { title: 'semHeader' },
    },
    {
        path: '/resumo',
        name: 'resumo',
        component: Resumo,
        meta: { title: 'semHeader' },
    },
    {
        path: '/tarefas',
        name: 'tarefas',
        component: TelaTarefas,
        meta: { title: 'Alertas de Validade', isAlerta: true },
    },
    {
        path: '/tarefas/validade',
        name: 'tarefas.validade',
        component: TarefasValidade,
        meta: { title: 'Alertas de Validade', isAlerta: true, hasArrow: true },
    },
    {
        path: '/tarefas/verificacao',
        name: 'tarefas.verificacao',
        component: TarefasVerificacao,
        meta: {
            title: 'Alertas de Validade',
            isSave: true,
            hasArrow: true,
            hasNoRefresh: true,
        },
    },
    {
        path: '/produtos',
        name: 'produtos',
        component: TelaProdutos,
        meta: { title: 'Incluir Produtos', isProduto: true },
    },
    {
        path: '/produtos/descricao',
        name: 'produtos.descricao',
        component: ProdutosDescricao,
        meta: {
            title: 'Descrição de Produtos',
            isProduto: true,
            hasArrow: true,
            hasNoRefresh: true,
        },
    },
    {
        path: '/produtos/edit',
        name: 'produtos.edit',
        component: ProdutosEdit,
        meta: {
            title: 'Edição de Produtos',
            isSave: true,
            hasArrow: true,
            hasNoRefresh: true,
        },
    },
    {
        path: '/produtos/inclusao',
        name: 'incluirProdutos',
        component: IncluirProdutos,
        meta: {
            title: 'Inclusão',
            isSave: true,
            hasArrow: true,
            hasNoRefresh: true,
        },
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

export default router;
