<template>
    <header v-if="title !== 'semHeader'">
        <div class="header">
            <transition name="fade">
                <a v-if="hasArrow" @click="$router.go(-1)" class="arrow-back">
                    <img src="./assets/img/arrow-back.svg" alt="refresh-image" />
                </a>
            </transition>
            <a id="logo" href="/carregamento">
                <img src="../src/assets/img/logo-minus.png" alt="logo-minus" />
            </a>
            <p>{{ title }}</p>
            <button id="btnRefresh" :class="{ 'refresh-none': hasNoRefresh }" type="button" @click="sincronizaDados">
                <img src="./assets/img/refresh.svg" alt="refresh-image" />
                <span id="attNumber"></span>
            </button>
        </div>
    </header>

    <router-view v-slot="slotProps">
        <transition name="slide" mode="out-in">
            <component :is="slotProps.Component"></component>
        </transition>
    </router-view>

    <template v-if="!isSave && !isLogin">
        <footer v-if="title !== 'semHeader'">
            <router-link
                class="format-footer validade"
                :class="{ 'footer-branco': isProduto }"
                :to="{ name: 'tarefas' }"
            >
                <img v-if="isProduto" src="./assets/img/clock-grey.svg" alt="relogio" />
                <img v-else src="./assets/img/clock.svg" alt="relogio" />
                <h3>Alertas de Validade</h3>
            </router-link>
            <router-link
                class="format-footer inclusao"
                :class="[{ 'footer-branco': isAlerta }, { 'footer-active': isProduto }]"
                :to="{ name: 'produtos' }"
            >
                <img v-if="isAlerta" src="./assets/img/add.svg" alt="relogio" />
                <img v-else src="./assets/img/add-white.svg" alt="relogio" />
                <h3>Incluir Produtos</h3>
            </router-link>
        </footer>
    </template>

    <div class="btn-save" v-if="isLogin">
        <button class="salvar" type="submit" form="formLogin">Entrar</button>
    </div>
</template>

<script>
export default {
    data() {
        return {
            title: 'Valid',
            hasArrow: false,
            hasNoRefresh: false,
            isProduto: false,
            isAlerta: false,
            isSave: false,
            isLogin: false,
        };
    },

    watch: {
        $route: function (val) {
            this.title = val.meta.title;
            this.hasArrow = val.meta.hasArrow;
            this.hasNoRefresh = val.meta.hasNoRefresh;
            this.isProduto = val.meta.isProduto;
            this.isAlerta = val.meta.isAlerta;
            this.isSave = val.meta.isSave;
            this.isLogin = val.meta.isLogin;
        },
    },
};
</script>

<style>
:root {
    --verde: #56bb8f;
    --branco: #ffffff;
    --preto: #000;
    --cinza: #cdcdcd;
    --cinza-escuro: #5f6368;
    --cinza-claro: #f4f4f4;
    --azul-escuro: #0f1628;
}

* {
    font-family: 'Karla', sans-serif;
    font-size: 18px;
    margin: 0;
    padding: 0;
    text-decoration: none;
    list-style: none;
    box-sizing: border-box;
    font-weight: 400;
}

body {
    width: 100%;
    min-height: 100%;
    scroll-behavior: smooth;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}

.fade-enter-to,
.fade-leave-from {
    opacity: 1;
}

.fade-enter-active,
.fade-leave-active {
    transition: all 0.2s ease;
}

.slide-enter-from,
.slide-leave-to {
    transform: translateX(-100vw);
}

.slide-enter-to,
.slide-leave-from {
    transform: translateX(0px);
}

.slide-enter-active,
.slide-leave-active {
    transition: all 0.4s ease-in-out;
}

.slide-both-enter-from {
    transform: translateX(-100vw);
}

.slide-both-leave-to {
    transform: translateX(100vw);
}

.slide-both-enter-to,
.slide-both-leave-from {
    transform: translateX(0px);
}

.slide-both-enter-active,
.slide-both-leave-active {
    transition: all 0.4s ease-in-out;
}

header {
    width: 100%;
    display: flex;
    min-height: 56px;
    position: fixed;
    top: 0;
    background-color: #fff;
    z-index: 2;
}

.header {
    width: 100%;
    align-items: center;
    padding: 0px 15px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid var(--cinza);
}

#logo {
    display: flex;
}

#logo img {
    width: 50px;
}

#btnRefresh {
    background-color: transparent;
    display: flex;
    border: none;
}

#btnRefresh img {
    width: 25px;
}

#btnRefresh.refresh-none {
    display: none;
}

.refresh-none {
    display: none;
}

.arrow-back {
    cursor: pointer;
    display: flex;
    align-items: center;
}

footer {
    width: 100%;
    position: fixed;
    bottom: 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    background-color: var(--azul-escuro);
    box-shadow: 0 0 5px #cdcdcd;
}

footer a img {
    width: 18px;
}

.format-footer {
    place-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 12px;
}

.format-footer h3 {
    font-size: 15px;
    margin-top: 5px;
    color: var(--branco);
    font-weight: 500;
}

.inclusao h3 {
    color: #4e4e4e;
}

.footer-active h3 {
    color: var(--branco);
}

.footer-branco {
    background-color: #fff;
    color: #4e4e4e;
}

.footer-branco h3 {
    color: #4e4e4e;
}

.btn-save {
    display: flex;
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 0;
    background-color: #fff;
    width: 100%;
    box-shadow: 0 0 5px #cdcdcd;
    justify-content: flex-end;
}

.salvar {
    cursor: pointer;
    min-width: 64px;
    border: none;
    padding: 20px 45px;
    background-color: #fff;
    color: var(--verde);
    font-weight: 600;
    font-size: 20px;
}

.salvar:hover {
    box-shadow: none;
    background-color: var(--verde);
    color: #fff;
}

@media (max-width: 319px) {
    .format-footer h3 {
        font-size: 13px;
    }
}
</style>
